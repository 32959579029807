import React, { useEffect, useState } from "react";
import { Box, Flex, IconButton, useColorMode } from "theme-ui";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LanguageIcon from "@material-ui/icons/Language";

import Link from "components/Link";
import SelectSettingField from "components/Fields/SelectSetting";
import Icon from "components/Icons";

import { COLOR_MODES, LANGUAGE_MODES } from "components/navbar/constants";

const Breadcrumb = ({ item, isSelected }) => {
  return (
    <Link
      to={item.to}
      sx={{
        variant: "links.breadcrumb",
      }}
      className={isSelected ? "isSelected" : ""}
    >
      {item.text}
    </Link>
  );
};

const Breadcrumbs = ({ items }) => {
  const [languageMode, setLanguageMode] = useState("english");
  const [colorMode, setColorMode] = useColorMode();

  useEffect(() => {
    if (!COLOR_MODES.includes(colorMode)) {
      setColorMode("light")
    }
  }, [])

  return (
    <Box
      sx={{
        display: ["none", "none", "flex"],
        alignItems: "center",
        gap: "10px",
        py: 1.5,
        px: 10,
        background: "grey.100",
        height: 9,
      }}
    >
      <Flex sx={{ flex: 1, alignItems: "center" }}>
        {items.map((item, index) => {
          const isLast = index === items.length - 1;
          return (
            <React.Fragment key={`breadcrumb-${item.text}`}>
              <Breadcrumb item={item} isSelected={isLast} />
              {!isLast && (
                <IconButton
                  variant="icon.light"
                  sx={{
                    width: 1,
                    height: 1,
                    color: "text.secondary",
                    fontSize: 4,
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              )}
            </React.Fragment>
          );
        })}
      </Flex>
      {COLOR_MODES.length > 1 && (
        <SelectSettingField
          icon={
            colorMode === "dark" ? (
              <Icon icon="far fa-moon" />
            ) : (
              <Icon icon="far fa-sun" />
            )
          }
          onChange={(item) => setColorMode(item)}
          options={COLOR_MODES}
          value={colorMode || "light"}
          sx={{ mr: 6 }}
        />
      )}
      {/* <SelectSettingField
        icon={<Icon icon="fas fa-globe" />}
        onChange={(item) => setLanguageMode(item)}
        options={LANGUAGE_MODES}
        value={languageMode}
      /> */}
    </Box>
  );
};
export default Breadcrumbs;
