/** @jsx jsx */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Close,
  Divider,
  Flex,
  jsx,
  MenuButton,
  useColorMode,
} from "theme-ui";

import PublicIcon from "@material-ui/icons/Public";
import DateRange from "@material-ui/icons/DateRange";
import PersonOutline from "@material-ui/icons/PersonOutline";
import LanguageIcon from "@material-ui/icons/Language";

import MenuItem from "components/navbar/MenuItem";
import BrandLogo from "components/BrandLogo";
import { AuthContext } from "Authentication";
import SelectSettingField from "components/Fields/SelectSetting";
import Icon from "components/Icons";

import { COLOR_MODES, LANGUAGE_MODES } from "components/navbar/constants";

const HamburgerMenu = ({ currentPage }) => {
  const [showMenu, setShowMenu] = useState(false);
  const callbackRef = useRef(null);
  const { isSignedIn, signOut } = useContext(AuthContext);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (showMenu) {
      callbackRef.current = toggleMenu;

      document.addEventListener("backbutton", callbackRef.current);
    } else if (callbackRef.current) {
      document.removeEventListener("backbutton", callbackRef.current);
      callbackRef.current = null;
    }

    return () => {
      if (callbackRef.current) {
        document.removeEventListener("backbutton", callbackRef.current);
      }
    };
  }, [showMenu]);

  const [languageMode, setLanguageMode] = useState("english");
  const [colorMode, setColorMode] = useColorMode();

  useEffect(() => {
    if (!COLOR_MODES.includes(colorMode)) {
      setColorMode("light")
    }
  }, [])

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          display: showMenu ? ["block", null, "none"] : "none",
          height: "screen-h",
          width: "full",
          background: "backdrop",
          zIndex: "nav",
          top: 0,
        }}
        onClick={toggleMenu}
      />
      <Box sx={{ display: [null, null, "none"], width: "full", height: 14 }}>
        <Box
          sx={{
            position: "fixed",
            zIndex: "nav",
            bg: "componentBG1",
            width: "full",
          }}
        >
          <Box sx={{ display: "flex" }}>
            {showMenu ? (
              <Box sx={{ color: "primary", background: "primary.active" }}>
                <Close
                  aria-label="Toggle Menu"
                  sx={{
                    width: 12,
                    height: 14,
                  }}
                  onClick={toggleMenu}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  color: "primary",
                  borderRight: "1px solid",
                  borderColor: "lineDetail",
                }}
              >
                <MenuButton
                  aria-label="Toggle Menu"
                  sx={{
                    width: 12,
                    height: 14,
                  }}
                  onClick={toggleMenu}
                />
              </Box>
            )}
            <Flex sx={{ alignItems: "center" }}>
              <Box sx={{ pl: 2.5 }}>
                <BrandLogo width={12} />
              </Box>
              <Box sx={{ pl: 2.5 }}>{currentPage}</Box>
            </Flex>
          </Box>
          <Box
            sx={{
              position: "fixed",
              zIndex: "nav",
              display: showMenu ? "block" : "none",
              width: 86,
              height: "full",
              bg: "componentBG1",
            }}
          >
            <MenuItem
              Icon={PublicIcon}
              title="Explore"
              url="/explore"
            ></MenuItem>
            {isSignedIn ? (
              <>
                <MenuItem
                  Icon={DateRange}
                  title="Itinerary"
                  url="/itinerary"
                ></MenuItem>
                <MenuItem
                  Icon={PersonOutline}
                  title="My profile"
                  url="/profile"
                ></MenuItem>
                <Box onClick={signOut}>
                  <MenuItem Icon={PersonOutline} title="Log Out"></MenuItem>
                </Box>
              </>
            ) : (
              <MenuItem
                Icon={PersonOutline}
                title="Log In"
                url="/sign-in"
              ></MenuItem>
            )}
            <Box
              className="isSelected"
              sx={{
                variant: "links.breadcrumb",
                position: "fixed",
                bottom: "0",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 8,
                height: 12,
                width: "inherit",
                px: 4,
                background: "grey.100",
                textDecoration: "none",
              }}
            >
              {COLOR_MODES.length > 1 && (
                <SelectSettingField
                  icon={
                    colorMode === "dark" ? (
                      <Icon icon="far fa-moon" />
                    ) : (
                      <Icon icon="far fa-sun" />
                    )
                  }
                  onChange={(item) => setColorMode(item)}
                  options={COLOR_MODES}
                  value={colorMode || "light"}
                  menuDirection="bottom"
                />
              )}
              {/* <SelectSettingField
                icon={<Icon icon="fas fa-globe" />}
                onChange={(item) => setLanguageMode(item)}
                options={LANGUAGE_MODES}
                value={languageMode}
              /> */}
            </Box>
          </Box>
          <Divider sx={{ margin: 0, color: "lineDetail" }} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HamburgerMenu;
