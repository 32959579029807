import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Input, Label, Paragraph } from "theme-ui";
import ArrowDropDownOutlined from "@material-ui/icons/ArrowDropDownOutlined";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../Authentication/AuthProvider";
import FloatingAlert from "components/FloatingAlert";
import Link from "components/Link";
import { LoadingIndicator } from "components";
import ProfileLayout from "profile/ProfileLayout";
import SearchComponent from "components/SearchComponent";
import TextInput from "components/Fields/Text";
import axpAxios from "utils/axpAxios";
import { useFetchBookings } from "utils";
import SEO from "components/SEO";

export default function AddExistingBooking({}) {
  const BREADCRUMB_LINKS = [
    { text: "My profile", to: "/profile" },
    { text: "Bookings", to: "/profile/bookings" },
  ];
  const PAGE_NAME = "Bookings";

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm();

  const { profile, refreshProfile } = useContext(AuthContext);
  const { propertiesList } = useFetchBookings();

  const [isLoading, setIsLoading] = useState(false);
  const [sucessfullyLinked, setSucessfullyLinked] = useState(false);
  const [linkError, setLinkError] = useState(false);

  const addExistingBookingSubmit = async (data) => {
    setIsLoading(true);
    axpAxios
      .post("/guest/v1/link_booking", data)
      .then((response) => {
        refreshProfile();
        setSucessfullyLinked(true);
      })
      .catch((error) => {
        setLinkError(true);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (profile) {
      setValue("first_name", profile.firstName);
      setValue("last_name", profile.lastName);
    }
  }, [profile]);

  const handleSelectedPropertyOption = (event, value, reason) => {
    setValue("location_id", value ? value.axp_location_id : "");
    trigger("location_id");
  };

  return (
    <ProfileLayout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      <FloatingAlert
        variant="success"
        data={{
          title: "Booking linked",
          description: "Booking successfully linked.",
        }}
        isOpen={sucessfullyLinked}
        onClose={() => setSucessfullyLinked(false)}
      />
      <FloatingAlert
        variant="error"
        data={{
          title: "Error",
          description: "There was an error linking your booking.",
        }}
        isOpen={linkError}
        onClose={() => setLinkError(false)}
      />

      {isLoading && <LoadingIndicator sx={{ height: "100vh" }} />}
      <Heading as="h6" variant="heading6" sx={{ ml: [4, 0] }}>
        Add Existing Booking
      </Heading>
      <Paragraph variant="body1" sx={{ color: "text.secondary", ml: [4, 0] }}>
        Add the details of your existing booking so that we can personalise your
        experience.
      </Paragraph>
      <Box sx={{ height: "fit-content", marginTop: 6 }}>
        <Box
          as="form"
          sx={{
            bg: "backgroundBG1",
            borderRadius: 1,
            position: "relative",
            p: 4,
          }}
          onSubmit={handleSubmit(addExistingBookingSubmit)}
        >
          <Box sx={{ my: 4 }}>
            <TextInput
              label="First name"
              register={register("first_name", { required: true })}
              helper={errors.first_name && errors.first_name.type}
            />
          </Box>
          <Box sx={{ my: 4 }}>
            <TextInput
              label="Last name"
              register={register("last_name", { required: true })}
              helper={errors.last_name && errors.last_name.type}
            />
          </Box>

          <Box>
            <SearchComponent
              options={propertiesList}
              sx={{
                zIndex: "low",
                variant: "forms.input",
                boxShadow: "none",
                height: 10,
                p: 0,
                borderColor: "lineDetail",
              }}
              renderInput={(params) => {
                return (
                  <Box
                    ref={params.InputProps.ref}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 10,
                    }}
                  >
                    <Input
                      sx={{
                        variant: "forms.input",
                        fontFamily: "input",
                        borderWidth: 0,
                        ":placeholder-shown": {
                          background: "none",
                        },
                        background: "none",
                      }}
                      type="text"
                      placeholder={"Property" || ""}
                      register={register("location_id", { required: true })}
                      {...params.inputProps}
                    />
                    <Box sx={{ color: "primary", fontSize: "20px" }}>
                      <ArrowDropDownOutlined />
                    </Box>
                  </Box>
                );
              }}
              renderOption={(option) => (
                <Box sx={{ display: "flex", flexDirection: "row " }}>
                  <Paragraph variant="body1" sx={{ marginLeft: 2 }}>
                    {option.name}
                  </Paragraph>
                </Box>
              )}
              handleChange={handleSelectedPropertyOption}
            />
            {errors.location_id && (
              <Label variant="caption" sx={{ color: "textSecondary" }}>
                {errors.location_id.type}
              </Label>
            )}
          </Box>

          <Box sx={{ my: 4 }}>
            <TextInput
              label="Confirmation number"
              register={register("booking_reference", { required: true })}
              helper={errors.booking_reference && errors.booking_reference.type}
            />
          </Box>
          <Flex
            sx={{
              display: ["none", "flex"],
              alignItems: "center",
              gap: 4,
              justifyContent: "flex-end",
              p: 4,
            }}
          >
            <Link variant="button.text" to="/profile/bookings">
              Cancel
            </Link>
            <Button type="submit">Link booking</Button>
          </Flex>

          <Flex
            sx={{
              display: ["flex", "none"],
              flexDirection: "column",
              justifyContent: "center",
              p: 4,
            }}
          >
            <Button type="submit">Link booking</Button>
            <Link
              variant="button.text"
              to="/profile/bookings"
              sx={{ justifyContent: "center", p: 4 }}
            >
              Cancel
            </Link>
          </Flex>
        </Box>
      </Box>
    </ProfileLayout>
  );
}

export const Head = () => <SEO title="Add booking" />;
