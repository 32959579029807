/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menu from "components/SearchComponent/Menu";
import HiddenPopper from "components/SearchComponent/HiddenPopper";

const SearchComponent = ({
  options,
  renderOption,
  renderInput,
  selectedSearchOption,
  setSelectedSearchOption,
  handleChange,
  ...props
}) => {
  return (
    <Box>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name}
        sx={{ variant: "forms.input.search" }}
        renderInput={renderInput}
        renderOption={renderOption}
        onChange={handleChange}
        PaperComponent={Menu}
        PopperComponent={HiddenPopper}
        {...props}
      />
    </Box>
  );
};

export default SearchComponent;
