/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import Link from "components/Link";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const MenuItem = ({ Icon, title, url }) => {
  return (
    <Link
      to={url}
      sx={{
        variant: "body1",
        display: "flex",
        alignItems: "center",
        width: "full",
        p: 4,
        borderBottom: "1px solid",
        borderColor: "lineDetail",
        color: "text",
        textDecoration: "none",
        "&:active, &:link": {
          color: "text",
        },
      }}
    >
      <Icon sx={{ color: "text.secondary", width: 6, height: 6 }} />
      <Text sx={{ flex: "1", ml: 4 }}>{title}</Text>
      <KeyboardArrowRight />
    </Link>
  );
};
export default MenuItem;
