import React, { useContext } from "react";
import { Box, Flex, Divider, useColorMode } from "theme-ui";
import HamburgerMenu from "./HamburgerMenu";
import Breadcrumbs from "./Breadcrumbs";
import Link from "components/Link";
import { AuthContext } from "Authentication/AuthProvider";
import BrandLogo from "components/BrandLogo";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import { ButtonWithIcon } from "components";
import { navigate } from "gatsby";

const BREADCRUMB_LINKS = [{ text: "Explore", to: "/" }];

function NavBar({ currentPage, breadcrumbs = BREADCRUMB_LINKS, ...props }) {
  const { profile, signOut, isSignedIn } = useContext(AuthContext);

  const NAVBAR_LINKS = [
    { text: "Explore", to: "/" },
    isSignedIn && { text: "Itinerary", to: "/itinerary" },
  ].filter(Boolean);

  const [colorMode, setColorMode] = useColorMode();

  return (
    <Box {...props}>
      <HamburgerMenu currentPage={currentPage} />
      <Breadcrumbs items={breadcrumbs} />
      <Flex
        as="nav"
        py={3}
        px={10}
        sx={{
          position: "sticky",
          display: ["none", "none", "flex"],
          gap: "20px",
          alignItems: "center",
          bg: "componentBG1",
        }}
      >
        <BrandLogo width={15} />
        <Box sx={{ flex: "1 1 auto" }}>
          {NAVBAR_LINKS.map((link) => (
            <Link
              key={`nav-link-${link.text}`}
              sx={{ variant: "links.nav" }}
              to={link.to}
              className={link.text === currentPage ? "isSelected" : ""}
            >
              {link.text}
            </Link>
          ))}
        </Box>
        {profile ? (
          <ButtonWithIcon
            variant="buttons.outlined"
            icon={<PersonOutlined />}
            sx={{ alignItems: "center" }}
            onClick={() => navigate("/profile")}
          >
            {profile.firstName}
          </ButtonWithIcon>
        ) : (
          <ButtonWithIcon onClick={() => navigate("/sign-in")}>
            Log in
          </ButtonWithIcon>
        )}
      </Flex>
      <Divider sx={{ margin: 0, color: "lineDetail" }} />
    </Box>
  );
}

export default NavBar;
