import React, { useMemo, useState } from "react";
import { Box, Flex, Grid, Text } from "theme-ui";
import { useClickOutside } from "utils";
import Icon from "../Icons";

const SelectSettingField = ({
  options,
  icon,
  sx,
  onChange,
  value,
  menuDirection = "top",
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const contianerRef = useClickOutside(() => {
    setOpen(false);
  });

  const currentLabel = useMemo(
    () => options.find((item) => item.value === value)?.label,
    [value]
  );

  return (
    <Flex
      sx={{
        position: "relative",
        ...sx,
      }}
      onFocus={() => setOpen(true)}
      onClick={() => setOpen((prevValue) => !prevValue)}
      ref={contianerRef}
      {...props}
    >
      {value && (
        <Text
          sx={{
            variant: "text.subtitle2",
            display: "inline-flex",
            justifyContent: "center",
            color: "primary",
            fontSize: 3,
            alignItems: "center",
            gap: 2,
          }}
        >
          {icon} {currentLabel} <Icon icon="fas fa-caret-down" />
        </Text>
      )}

      {open && (
        <Grid
          sx={{
            position: "absolute",
            [menuDirection]: "110%",
            right: ["unset", null, 0],
            left: [0, null, "unset"],
            zIndex: "menu",
            gridTemplateColumns: "1rem auto 1rem",
            bg: "componentBG3",
            width: 40,
            maxWidth: "90vw",
            gap: 2,
            boxShadow: "elevation3dp",
            "& > *": {
              gridColumn: "2",
            },
            "& > .fullSize": {
              gridColumn: "1 / span 3",
            },
          }}
        >
          <Box
            sx={{
              maxHeight: 64,
              overflow: "auto",
              gridColumn: "span 3",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {options.map((item) => (
              <Box
                key={`select-${item.value}`}
                variant="body1"
                sx={{
                  cursor: "pointer",
                  py: 2,
                  pl: 4,
                  "&:hover": {
                    background: "primary.selected",
                  },
                  background: item.value === value ? "primary.active" : "none",
                }}
                onClick={() => {
                  onChange(item.value);
                  setOpen(false);
                }}
              >
                <Icon
                  icon="fas fa-check"
                  sx={{
                    mr: 2,
                    color: item.value === value ? "current" : "transparent",
                  }}
                />
                {item.label}
              </Box>
            ))}
          </Box>
        </Grid>
      )}
    </Flex>
  );
};

export default SelectSettingField;
