import React from "react";
import { Box } from "theme-ui";

const Menu = ({ ...props }) => (
  <Box
    {...props}
    sx={{
      background: "componentBG3",
      borderRadius: 1,
      boxShadow: "elevation3dp",
    }}
  />
);

export default Menu;
